import 'core-js/stable';
import 'regenerator-runtime/runtime';
import svg4everybody from 'svg4everybody';
import $ from 'jquery';


svg4everybody();

window.$ = $;
window.jQuery = $;


