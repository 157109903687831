import './vendor';
require('/src/js/vendor/magnific/jquery.magnific-popup.min.js')
require('/src/js/vendor/beforeafter/before-after.min.js')
import Inputmask from "../.../../../node_modules/inputmask/dist/jquery.inputmask.min";

$(".inputmask").inputmask({"mask": "+7 (999) 999-9999"});


$('.ba-slider').each(function(){
	$(this).beforeAfter();
});


/*Magnific модальное окно */
$('.popup-with-form').magnificPopup({
	type: 'inline',
	preloader: false,
	focus: '#name',
	mainClass: 'mfp-fade',
	closeMarkup: '<button title="Закрыть" type="button" class="mfp-close form-close"><svg><use xlink:href="./images/sprites.svg#close"></use></svg></button>',
	callbacks: {
		beforeOpen: function() {
			if($(window).width() < 700) {
				this.st.focus = false;
			} else {
				this.st.focus = '#name';
			}
		},
	}
});


document.querySelector('.hero__menu').addEventListener('click', function(event) {
	var useElement = this.querySelector('svg use');
	var xlinkHref = useElement.getAttribute('xlink:href');
	if (xlinkHref.includes('#menu')) {
	    useElement.setAttribute('xlink:href', xlinkHref.replace('#menu', '#close'));
	} else {
	    useElement.setAttribute('xlink:href', xlinkHref.replace('#close', '#menu'));
	}
	document.body.classList.toggle('noscroll');
	document.querySelector('.menublock').classList.toggle('is-open');
});
    


document.addEventListener('DOMContentLoaded', function() {
	document.querySelectorAll('ul.menublock__menu li').forEach(function(item) {
		item.addEventListener('click', function(e) {
		var useElement = document.querySelector('.hero__menu svg use');
		var xlinkHref = useElement.getAttribute('xlink:href');
		var baseUrl = xlinkHref.split('#')[0];
		useElement.setAttribute('xlink:href', baseUrl + '#menu');
		document.body.classList.remove('noscroll');
		document.querySelector('.menublock').classList.remove('is-open');
		});
	});
});
    


document.querySelector('.menublock__close').addEventListener('click', function(event) {
	var useElement = document.querySelector('.hero__menu svg use');
	var xlinkHref = useElement.getAttribute('xlink:href');
	if (xlinkHref.includes('#menu')) {
	    useElement.setAttribute('xlink:href', xlinkHref.replace('#menu', '#close'));
	} else {
	    useElement.setAttribute('xlink:href', xlinkHref.replace('#close', '#menu'));
	}
	document.body.classList.toggle('noscroll');
	document.querySelector('.menublock').classList.toggle('is-open');
});
    

if(document.querySelector(".field__file")) {
	let fields = document.querySelectorAll('.field__file');
	Array.prototype.forEach.call(fields, function (input) {
	let label = input.nextElementSibling,
		labelVal = label.querySelector('.field__file-fake').innerText;
	
	input.addEventListener('change', function (e) {
		let countFiles = '';
		if (this.files && this.files.length >= 1)
		countFiles = this.files.length;
	
		if (countFiles)
		// label.querySelector('.field__file-fake').innerText = 'Выбрано файлов: ' + countFiles;
		label.querySelector('.field__file-fake').innerText = 'Файл добавлен ';
		else
		label.querySelector('.field__file-fake').innerText = labelVal;
	});
	});
}



document.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll('.form .btn').forEach(btn => {
	  btn.addEventListener('click', (e) => {
	    const form = btn.closest('.form');
	    const inputs = form.querySelectorAll('.form__input[required]');
	    let isValid = true;
	    const errorMessages = [];
	    
	    inputs.forEach(input => {
	      if (!input.value.trim()) {
		input.classList.add('form__input--error');
		const placeholder = input.getAttribute('placeholder');
		errorMessages.push(placeholder);
		isValid = false; 
	      } else {
		input.classList.remove('form__input--error');
	      }
	    });
	    
	    const errorMessageContainer = form.querySelector('.form__errormessage');
	    if (!isValid) {
	      e.preventDefault(); 
	      errorMessageContainer.textContent = `Поле(-я) - "${errorMessages.join(', ')}" не заполнены(-о)`;
	    } else {
	      errorMessageContainer.textContent = ''; 
	    }
	  });
	});
      });
      